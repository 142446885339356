import { callWithNuxt } from '#app';
import type { Seo } from '~/types';

// utils
import get from 'lodash.get';
import camelcase from 'lodash.camelcase';

type OperationType = 'Page' | 'Category';

const ogMapping: Record<string, string> = {
  title: 'ogTitle',
  description: 'ogDescription',
  image: 'ogImage',
};

export default async function useApiSeo(
  slug: string = '', 
  type: OperationType = 'Page',
  fallbackSeo: Partial<Seo> = {},
): Promise<void> {
  if (!slug.length) return;

  const app = useNuxtApp();
  const route = useRoute();

  const { error, data: seo } = await useAsyncGql({
    operation: type,
    variables: { slug },
    options: {
      transform: data => {
        const paths: Record<OperationType, string> = {
          Page: 'page.seo',
          Category: 'blog_category.data.seo',
        };
        
        return get(data, paths[type]);
      },
    }
  });

  let seoData = seo.value;

  if (error.value || !seo.value) {
    console.warn(`Failed to fetch SEO for "${route.path}" page. Using fallback data.`);
    
    seoData = fallbackSeo;
  }

  if (!seoData) return;

  const formattedSeo: Record<string, string | boolean> = {};

  for (const key of Object.keys(seoData) as Array<keyof Seo>) {
    const value = seoData[key];
  
    if (!isValidSeoValue(value)) continue;

    formattedSeo[camelcase(key)] = value as string | boolean;

    const ogKey = ogMapping[key];

    if (ogKey && value) {
      formattedSeo[ogKey] = value as string;
    }
  }
  
  if ('robots' in formattedSeo) {
    if (formattedSeo.robots === false) {
      formattedSeo.robots = 'noindex, nofollow';
    } else {
      delete formattedSeo.robots;
    }
  }

  callWithNuxt(app, useSeoMeta, [formattedSeo]);
}


function isValidSeoValue(value: unknown): boolean {
  return typeof value === 'boolean' || (typeof value === 'string' && value.trim().length > 0);
}